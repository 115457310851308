import { defineComponent, defineAsyncComponent, reactive, watch, computed } from 'vue';
import { useStore } from 'vuex';
import { Selector } from '@hems/component';
import { getPrivacyPolicy } from '@hems/component/src/termAndCondition';
import { Constant } from '@hems/util';
const termsVerEU = process.env.VUE_APP_TERMS_VER_EU || '';
const termsVerAU = process.env.VUE_APP_TERMS_VER_AU || '';
const termsVerUS = process.env.VUE_APP_TERMS_VER_US || '';
const termsVerNZ = process.env.VUE_APP_TERMS_VER_NZ || '';
const PrivacyEUEN = defineAsyncComponent(() => getPrivacyPolicy('EU', termsVerEU, 'en'));
const PrivacyEUDE = defineAsyncComponent(() => getPrivacyPolicy('EU', termsVerEU, 'de'));
const PrivacyEUFR = defineAsyncComponent(() => getPrivacyPolicy('EU', termsVerEU, 'fr'));
const PrivacyEUIT = defineAsyncComponent(() => getPrivacyPolicy('EU', termsVerEU, 'it'));
const PrivacyEUKO = defineAsyncComponent(() => getPrivacyPolicy('EU', termsVerEU, 'ko'));
const PrivacyEUPT = defineAsyncComponent(() => getPrivacyPolicy('EU', termsVerEU, 'pt'));
const PrivacyAUEN = defineAsyncComponent(() => getPrivacyPolicy('AU', termsVerAU, 'en'));
const PrivacyUSEN = defineAsyncComponent(() => getPrivacyPolicy('US', termsVerUS, 'en'));
const PrivacyNZEN = defineAsyncComponent(() => getPrivacyPolicy('NZ', termsVerNZ, 'en'));
export default defineComponent({
    name: 'TermAndConditionPage',
    components: {
        Selector,
        PrivacyEUEN,
        PrivacyEUDE,
        PrivacyEUFR,
        PrivacyEUIT,
        PrivacyEUKO,
        PrivacyEUPT,
        PrivacyAUEN,
        PrivacyUSEN,
        PrivacyNZEN,
    },
    setup() {
        const store = useStore();
        const appCtx = store.state.appCtx;
        const langOptionsEU = Constant.languages;
        const langOptionsAU = Constant.languages.filter((item) => item.value === 'en');
        const state = reactive({
            region: appCtx.envLocale,
            lang: ['AU', 'US'].includes(appCtx.envLocale) ? 'en' : appCtx.langCd,
            langOptions: ['AU', 'US'].includes(appCtx.envLocale) ? langOptionsAU : langOptionsEU,
        });
        const componentName = computed(() => `Privacy${state.region.toUpperCase()}${state.lang.toUpperCase()}`);
        watch(() => state.region, () => {
            state.langOptions = ['AU', 'NZ', 'US'].includes(state.region) ? langOptionsAU : langOptionsEU;
            state.lang = 'en';
        });
        return {
            state,
            componentName,
        };
    },
});
