import { defineComponent, defineAsyncComponent, reactive, computed } from 'vue';
import { useStore } from 'vuex';
import { Selector } from '@hems/component';
import { getPrivacyPolicy } from '@hems/component/src/termAndCondition';
import { Constant } from '@hems/util';
const termsVerEU = process.env.VUE_APP_TERMS_VER_EU || '';
const PrivacyEUEN = defineAsyncComponent(() => getPrivacyPolicy('EU', termsVerEU, 'en'));
const PrivacyEUDE = defineAsyncComponent(() => getPrivacyPolicy('EU', termsVerEU, 'de'));
const PrivacyEUFR = defineAsyncComponent(() => getPrivacyPolicy('EU', termsVerEU, 'fr'));
const PrivacyEUIT = defineAsyncComponent(() => getPrivacyPolicy('EU', termsVerEU, 'it'));
const PrivacyEUKO = defineAsyncComponent(() => getPrivacyPolicy('EU', termsVerEU, 'ko'));
const PrivacyEUPT = defineAsyncComponent(() => getPrivacyPolicy('EU', termsVerEU, 'pt'));
export default defineComponent({
    name: 'EUTermsAndConditionPage',
    components: {
        Selector,
        PrivacyEUEN,
        PrivacyEUDE,
        PrivacyEUFR,
        PrivacyEUIT,
        PrivacyEUKO,
        PrivacyEUPT,
    },
    setup(props, { emit }) {
        const store = useStore();
        const appCtx = store.state.appCtx;
        const langOptionsEU = Constant.languages;
        const state = reactive({
            region: 'EU',
            lang: appCtx.langCd,
            langOptions: langOptionsEU,
        });
        const componentName = computed(() => `Privacy${state.region.toUpperCase()}${state.lang.toUpperCase()}`);
        return {
            state,
            componentName,
        };
    },
});
